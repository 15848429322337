<template>
  <div class="user">
    <div class="title flexB" style="margin-top: 0;background:#fff;">
      <h2 class="top-bar-site-logo">
        <a href="javascript:void(0);" @click = "navigateToControlUser" class="top_site_bar_logo"  style="width: 190px;">
          <img v-if="getImageSrc(accountId , 'gnb')"
              :src="require(`/src/assets${getImageSrc(accountId , 'gnb')}`)" style="width: 100%;">
        </a>
        <div class="top-system-title">
          <img 
            v-if="getImageSrc(accountId , 'gnbTopImage')"
            :src="require(`/src/assets${getImageSrc(accountId , 'gnbTopImage')}`)"
          >
          <!-- acoountId 별로 문구 지정 -->
          <h2 v-if="getContentString('hsg' , 'title') !== null" >
            {{getContentString(this.accountId , 'title')}}
          </h2>
        </div>
      </h2>

      <div class="btn-wrap">
        <button v-if="monitorVisible == 1" :class="{ active: monitorVisible == 1 ? true : false }" @click="handleTab(2)"
                class="medium monitor-tab"><img src="@/assets/images/LNB_dashboard.png" alt="icon" />멀티뷰 모니터</button>
        <button v-if="monitorVisible == 2" :class="{ active: monitorVisible == 2 ? true : false }" @click="handleTab(1)"
                class="medium monitor-tab"><img src="@/assets/images/LNB_comparison.png" alt="icon" />기본 모니터</button>
      </div>
    </div>

    <DroneLiveMap v-if="monitorVisible == 1" style="height: calc(100vh - 50px);" />
    <DroneLiveMultiView v-if="monitorVisible == 2" style="height: calc(100vh - 50px);" />
  </div>
</template>
<style lang="scss">
.title {
  display: flex;
}
.top-bar-site-logo{
  display: flex;
    align-items: center;
    margin: 0;
}
.top_site_bar_logo > img{width: 100%;}
.top-system-title{
  margin-left: 60px;
  width: 250px;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img{
    width: max-content;
  }
  h2{
    font-size: 1.8rem;
    font-weight: 700;
    color: #101010;
    margin: 0;
    line-height: 28px;
  }
}
.screen-enter /* .fade-leave-active below version 2.1.8 */
{
  transition: opacity 0.2s;
  opacity: 0.2;
  min-width: 0px;
  width: 0%;
}

.screen-enter-active {
  transition: opacity 0.2s;
  min-width: 645px;
  width: 40%;
}

.small-map {
  width: calc(100% - 697px) !important;
}

.droneVal span {
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: 3px;
}
.monitor-tab{
  width: 150px;
  margin-right: 10px;
  border-radius: 5px;
  background: #007df9 !important;
  border: 0px !important;
  color: rgb(255, 255, 255) !important;
}
.monitor-tab > img{width: 18px;margin-right: 5px;}
</style>
<script>

import DroneLiveMap from "./monitoring/DroneLiveMap";
import DroneLiveMultiView from "./monitoring/DroneLiveMultiView";
import {mapState} from "vuex";
import {getImageSrc , getContent} from "@/util/common";

/*import MapStationLive from "./skyView/MapStationLive";*/
export default {
  name: "Home",
  components: {DroneLiveMap, DroneLiveMultiView /*MapStationLive*/},

  props: {
    eventId: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: null,
      monitorVisible: 1,
      id: null,

      windowHeight: window.innerHeight,
      typeChangeMapShowHeight: window.innerHeight - 90,
    };
  },
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
      accountId: state => state.store.accountId,
    }),
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    //페이지 리사이즈
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.typeChangeMapShowHeight = this.windowHeight - 90;
    },
    handlePop() {
      if(this.monitorVisible == 1){
       window.open(
            `/${this.accountId}/dashboard/monitoring/DroneLiveMap`,
            ``,
            "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
        );
      }else{
        window.open(
            `/${this.accountId}/dashboard/monitoring/DroneLiveMultiView`,
            ``,
            "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
        );
      }
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.monitorVisible = id;
      //localStorage.setItem("LiveEventInfoMain", this.monitorVisible);
      //localStorage.removeItem("LiveEventInfoSub");
    },
    navigateToControlUser() {
      this.$router.push(`/${this.accountId}/eventControl/liveListControlUser`);
    },
    getImageSrc(accountId , type){
        return getImageSrc(accountId,type)
    },
    getContentString(accountId , type){
       return getContent(accountId,type)
    },
  }
}
</script>
